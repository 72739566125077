<template>
    <div class="main-block footer">
        <v-layout>
            <div class="boxapp no-help">
                <!-- boxapp head -->
                <div class="boxapp-head">
                    <router-link :to="{ name: routes.app.VolunteeringList, query: { entityId: 35, institutionId: 19 } }">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </router-link>
                    <!-- <a href="#" @click="$router.go(-1)">
                      <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </a>-->
                    <h1>Galeria de Fotos</h1>
                </div>

                
                <!-- boxapp body -->
                <div class="box-main">
                    <StepsVolunteering :page-options="pageOptions" :volunteering="volunteering" />
                    <div class="section hero-preview">
                        <!-- section head -->
                        <div class="head">
                            <h2>Hero Preview *</h2>
                            <p class="text-gray-dark">Projeto: {{ project.shortName }}</p>
                        </div>

                        <!-- section body -->
                        <div class="wrapper">
                            <v-layout row wrap>
                                <v-flex preview>
                                    <!-- Image background -->
                                    <div
                                        ref="heroImage"
                                        class="image-bg"
                                        :style="{ backgroundImage: getImage() }"

                                    ></div>

                                    <!-- tools -->
                                    <div
                                        class="hero-tools left"
                                        :class="{ 'preview-off': !heroPreview, 'preview-on': heroPreview, }"
                                    >
                                        <!-- image opacity -->
                                        <!-- <div
                                          class="sub-box slider"
                                          :class="{ active: showHeroImageOpacitySlider }"
                                        >
                                          <v-slider
                                            v-model="projectLandingPageConfig.heroImageOpacity"
                                            min="0"
                                            max="1"
                                            step="0.1"
                                            thumb-label="always"
                                            color="#02ACB4"
                                            @change="save()"
                                          ></v-slider>
                                        </div>-->
                                        <!-- overlay opacity -->
                                        <!-- <div
                                          class="sub-box slider"
                                          :class="{ active: showHeroOverlayOpacitySlider }"
                                        >
                                          <v-slider
                                            v-model="projectLandingPageConfig.heroOverlayOpacity"
                                            min="0"
                                            max="1"
                                            step="0.1"
                                            thumb-label="always"
                                            color="#02ACB4"
                                            @change="save()"
                                          ></v-slider>
                                        </div>-->
                                        <!-- <a @click="changeHeroAlignment()">
                                          <i
                                            class="fal"
                                            :class="{'fa-align-left':projectLandingPageConfig.heroAlignment==0, 'fa-align-center':projectLandingPageConfig.heroAlignment==1, 'fa-align-right':projectLandingPageConfig.heroAlignment==2}"
                                          ></i>
                                        </a>-->
                                        <!-- <a @click="heroPreview = !heroPreview" :class="{active:heroPreview}">
                                          <i class="fal fa-eye"></i>
                                        </a>-->
                                        <a
                                            @click="toggleHeroImageUpload()"
                                            :class="{ active: showHeroUploadImage }"
                                        >
                                            <i class="fal fa-image"></i>
                                        </a>
                                        <!-- <a href>
                                          <i class="fal fa-palette"></i>
                                        </a>-->
                                        <!-- <a
                                          @click="toggleHeroImageOpacitySlider()"
                                          :class="{active:showHeroImageOpacitySlider}"
                                        >
                                          <i class="custom-icon ci-contrast size-24"></i>
                                        </a>-->
                                        <!-- <a
                                          @click="toggleHeroOverlayOpacitySlider()"
                                          :class="{active:showHeroOverlayOpacitySlider}"
                                        >
                                          <i class="custom-icon ci-brightness size-24"></i>
                                        </a>-->
                                    </div>

                                    <!-- <div
                                      class="text-block"
                                      :class="{'text-left':projectLandingPageConfig.heroAlignment==0, 'text-center':projectLandingPageConfig.heroAlignment==1, 'text-right':projectLandingPageConfig.heroAlignment==2}"
                                    >
                                      <h5>Projeto de Voluntariado</h5>
                                      <h1>{{project.shortName}}</h1>
                                      <v-btn
                                        flat
                                        round
                                        color="white"
                                        class="btn-green-light mx-0 mt-4"
                                        large
                                      >CLIQUE AQUI PARA DOAR</v-btn>
                                    </div>-->
                                    <div
                                        class="overlay"
                                        :style="{ opacity: projectLandingPageConfig.heroOverlayOpacity, }"
                                    ></div>

                                    <!-- dropzone -->
                                    <div class="dropzone" :class="{ active: showHeroUploadImage }">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            @change="heroImageChanged"
                                        />
                                        <i class="fas fa-cloud-upload"></i>
                                        <h4>ARRASTE E SOLTE</h4>
                                        <p>
                                            uma imagem aqui, ou
                                            <span>procure</span>
                                        </p>
                                    </div>
                                </v-flex>
                                <v-flex dicas>
                                    <div class="text-gray-dark">
                                        <h4 class="text-red">
                                            Dicas para upload
                                            <i
                                                class="fal fa-lightbulb-exclamation"
                                            ></i>
                                        </h4>
                                        <p>
                                            Resolução mínima:
                                            <b>1600x800px</b>.
                                            <br />Resolução recomendada:
                                            <b
                                                class="text-primary"
                                            >2880 × 1300px</b>.
                                            <br />Envie
                                            imagens em JPG com no máximo
                                            <b>500KB</b>.
                                        </p>
                                        <p class="mw-half">
                                            Não utilize imagens com grande quantidade de informação,
                                            grande quantidade de pessoas ou objetos, com pessoas muito
                                            distântes, de baixa resolução ou sem foco.
                                        </p>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </div>

                        <BorderSeparation />

                        <div class="section image-grid">
                            <!-- image counter -->
                            <div class="pill gray right">{{ volunteeringFiles.length }} fotos</div>

                            <!-- section head -->
                            <div class="head">
                                <h2>Galeria de Fotos</h2>
                            </div>

                            <div style="position: relative">
                                <div
                                    class="btn-upload fal fa-cloud-upload"
                                    v-if="volunteeringFiles.length < 10"
                                >
                                    <input
                                        type="file"
                                        id="upload-activity"
                                        accept="image/*"
                                        @change="fileChanged"
                                        ref="upload_activity"
                                        multiple
                                    />
                                </div>
                                <div class="wrapper">
                                    <!-- Project image -->
                                    <div
                                        class="item"
                                        v-for="(volunteeringFile, index) in volunteeringFiles"
                                        :key="volunteeringFile.id"
                                    >
                                        <div class="tools">
                                            <a
                                                class="fal fa-search-plus"
                                                @click="openGalleryLightbox(index)"
                                            ></a>
                                            <a
                                                class="fal fa-trash"
                                                @click="openConfirmDeleteFileProjectModal(volunteeringFile.id)"
                                            ></a>
                                        </div>
                                        <FileImage :file="volunteeringFile.file" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AucAlert
                ref="confirmationComponent"
                title="Você tem certeza que deseja excluir esse arquivo?"
                confirmButtonText="Sim"
                cancelButtonText="Não"
                @confirm="deleteFile(selectedVolunteeringFileId)"
            />
        </v-layout>
        <vue-easy-lightbox
            :visible="galleryLightboxVisible"
            :imgs="getGalleryLightboxImgs()"
            :index="galleryLightboxIndex"
            @hide="galleryLightboxVisible = false"
        ></vue-easy-lightbox>
        <div class="footer-step" style="display: flex;">
                <v-btn
                flat
                round
                color="white"
                class="btn-default flex-inherit px-5"
                large
                v-model="pageOptions"
                @click="previousPage"
            >Voltar</v-btn>
            <div class="btn-right">
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-model="pageOptions"
                    @click="nextPage"
                >Próximo</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import BorderSeparation from "@/components/BorderSeparation.vue";
import FileProjectImage from "@/components/FileProjectImage.vue";
import AucAlert from "@/components/AucAlert.vue";
import { FileType } from "@/scripts/models/enums/fileType.enum";
import FooterStep from "@/components/FooterStep.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import Volunteering from "@/scripts/models/volunteering.model";
import Project from "@/scripts/models/project.model";
import VolunteeringFile from "@/scripts/models/volunteeringFile.model";
import ProjectLandingPageConfig from "@/scripts/models/projectLandingPageConfig.model";
import FileService from "@/scripts/services/file.service";
import FileProjectService from "@/scripts/services/fileProject.service";
import ProjectService from "@/scripts/services/project.service";
import VolunteeringService from "@/scripts/services/volunteering.service";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import FileImage from "@/components/FileImage.vue";
import { Routes } from "@/scripts/models/enums/routes.enum";
import ImageCompressor from "image-compressor.js";
export default {
    components: {
        BorderSeparation,
        AucAlert,
        FileProjectImage,
        FooterStep,
        FileImage,
    },
    props: ["projectId", "pageOptions"],
    data() {
        return {
            fileType: FileType,
            volunteeringFiles: [],
            fileService: new FileService(),
            projectService: new ProjectService(),
            volunteeringService: new VolunteeringService(),
            volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
            volunteeringAttributeOffers: [],
            project: new Volunteering(),
            heroVolunteeringFile: new VolunteeringFile(),
            volunteeringFile: new VolunteeringFile(),
            fileProjectService: new FileProjectService(),
            selectedVolunteeringFileId: null,
            heroPreview: false,
            showHeroOverlayOpacitySlider: false,
            showHeroImageOpacitySlider: false,
            showHeroUploadImage: false,
            projectLandingPageConfig: new ProjectLandingPageConfig(),
            saving: false,
            saveQueue: [],
            galleryLightboxVisible: false,
            galleryLightboxIndex: 0,
            routes: Routes,
            breadcrumbLevels: [
                { text: "Início" },
                { text: "Projeto de Voluntariado" },
                { text: "Galeria de fotos" },
            ],
            requiredFields: [],
            formIsOk: false
        };
    },
    async created() {
        await this.loadProject(this.$route.query.volunteeringId);
        this.$emit("changeLevels", this.breadcrumbLevels);
        this.pageOptions.currentPage = 4;
        this.requiredFields.push('image-bg')
        this.validationMethod = this.validate;
        this.onValidationSuccessMethod = this.setValidStep;
        this.onValidationFailMethod = this.setInvalidStep;
    },
    async mounted() {
        await this.checkForm()
    },
    methods: {
        previousPage() {
            this.$router.push({
                name: this.routes.app.VolunteeringStep3,
                query: {
                    volunteeringId: this.volunteering.id,
                },
            });
        },
        nextPage() {
            this.$router.push({
                name: this.routes.app.VolunteeringStep5,
                query: {
                    volunteeringId: this.volunteering.id,
                },
            });
        },
        async loadProject(id) {
            await this.volunteeringService.findById(id).then(async (data) => {
                this.volunteering = data;

                await this.volunteeringAttributeOfferService
                    .findByVolunteeringId(id)
                    .then((volunteeringAttributeOffers) => {
                        this.volunteering.volunteeringAttributeOffers = volunteeringAttributeOffers;
                        this.volunteeringAttributeOffers = volunteeringAttributeOffers;
                    });

                this.separeFiles();
            });
        },
        separeFiles() {
            this.volunteeringFiles = [];
            this.volunteering.volunteeringFiles.forEach((volunteeringFile) => {
                if (volunteeringFile.isHero)
                    this.heroVolunteeringFile = volunteeringFile;
                else if (!volunteeringFile.isFaviconFile)
                    this.volunteeringFiles.push(volunteeringFile);
            });
        },
        openGalleryLightbox(index) {
            this.galleryLightboxVisible = true;
            this.galleryLightboxIndex = index;
        },
        getGalleryLightboxImgs() {
            let imgs = [];
            if (this.volunteeringFiles == null) return [];
            for (let i = 0; i < this.volunteeringFiles.length; i++) {
                if (
                    this.volunteeringFiles[i] != null &&
                    this.volunteeringFiles[i].file != null
                )
                    imgs.push(this.volunteeringFiles[i].file.path);
            }
            return imgs;
        },
        changeHeroAlignment() {
            this.projectLandingPageConfig.heroAlignment = (this.projectLandingPageConfig.heroAlignment + 1) % 3;
            //this.save();
        },
        // toggleHeroImageOpacitySlider() {
        //   this.showHeroOverlayOpacitySlider = false;
        //   this.showHeroImageOpacitySlider = !this.showHeroImageOpacitySlider;
        // },
        // toggleHeroOverlayOpacitySlider() {
        //   this.showHeroImageOpacitySlider = false;
        //   this.showHeroOverlayOpacitySlider = !this.showHeroOverlayOpacitySlider;
        // },
        toggleHeroImageUpload() {
            this.showHeroUploadImage = !this.showHeroUploadImage;
            this.checkForm()
        },
        openConfirmDeleteFileProjectModal(id) {
            this.selectedVolunteeringFileId = id;
            this.$refs.confirmationComponent.open();
        },
        listVolunteeringFiles() {
            this.volunteeringService
                .listVolunteeringFiles(this.volunteering.id)
                .then((data) => {
                    this.volunteering.volunteeringFiles = data;
                    this.separeFiles();
                });
        },
        fileChanged(event) {
            this.volunteeringFile = new VolunteeringFile();
            var aux = 0;
            for (let i = 0; i < event.target.files.length; i++) {
                var file = event.target.files[i];
                let reader = new FileReader();
                this.volunteeringFile.file.name = file.name;
                reader.onload = async function () {
                    this.volunteeringFile.file.path = reader.result;
                    this.volunteeringFile.volunteeringId = this.volunteering.id;
                    this.save(this.volunteeringFile);
                }.bind(this);
                reader.readAsDataURL(file);
            }
        },
        deleteFile(volunteeringFileId) {
            this.volunteeringService
                .deleteVolunteeringFile(volunteeringFileId)
                .then(() => {
                    this.listVolunteeringFiles();
                });
        },
        getImage(callback) {
            if (this.heroVolunteeringFile.file.hasOwnProperty("imageContent")) {
                return "url(" + this.heroVolunteeringFile.file.imageContent + ")";
            } else {
                this.fileService
                    .render(this.heroVolunteeringFile.file.id)
                    .then(this.fileService_renderHeroFileCallback);
            }
            return "";
        },
        fileService_renderHeroFileCallback(data) {
            this.$set(this.heroVolunteeringFile.file, "imageContent", data);
        },
        heroImageChanged(event) {
            var file = event.target.files[0];
            let img = new Image();
            img.src = window.URL.createObjectURL(file);
            img.onload = function () {
                console.log(img.width + "x" + img.height);
                if (
                    img.width < 1600 ||
                    img.height < 800 ||
                    img.width > 2880 ||
                    img.height > 1300
                ) {
                    CommonHelper.swal(`Tamanho para upload da imagem deve ser entre 1600x800px e 2880x1300px. Sua imagem possui dimensões ${img.width} x ${img.height}`);
                } else {
                    new ImageCompressor(file, {
                        convertSize: 409600,
                        success: function (result) {
                            let reader = new FileReader();
                            this.heroVolunteeringFile.file.name = result.name;
                            reader.onload = function () {
                                this.$refs.heroImage.style.backgroundImage =
                                    "url(" + reader.result + ")";
                                this.heroVolunteeringFile.file.path = reader.result;
                                this.heroVolunteeringFile.file.imageContent = reader.result;
                                this.toggleHeroImageUpload();
                                this.heroVolunteeringFile.isHero = true;
                                this.save(this.heroVolunteeringFile);
                            }.bind(this);
                            reader.readAsDataURL(result);
                        }.bind(this),
                    });
                }
            }.bind(this);
        },
        save(volunteeringFile) {
            volunteeringFile.volunteeringId = this.volunteering.id;
            this.volunteeringService
                .saveVolunteeringFile(volunteeringFile)
                .then(this.listVolunteeringFiles);
        },
        async checkForm() {
            let requiredFieldsCounter = 0
            let value = ''
            for (const classname of this.requiredFields) {
                const elements = document.getElementsByClassName(classname)
                if (elements.length > 0) value = elements[0].style.backgroundImage.replace('url("','').replace(')','')
                
                if (value !== '' && value !== null) requiredFieldsCounter++
            }

            if (requiredFieldsCounter === this.requiredFields) this.formIsOk = true
            else this.formIsOk = false
        }
    },
};
</script>